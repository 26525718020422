import { useGoogleAdsLocationService } from "~/services/google-ads-location";

export const useGoogleAdsLocation = () => {
  const route = useRoute();
  const { getLocationById } = useGoogleAdsLocationService();
  const location = ref(null);

  onMounted(async () => {
    const locationId =
      route.query.location_interest_ms ||
      route.query.location_physical_ms;
    if (!locationId) return;

    const data = await getLocationById(locationId.toString());
    if (data) {
      location.value = data;
    }
  });

  const shouldShowLocation = computed(
    () =>
      location.value && location.value.target_type !== "Postal Code"
  );

  return {
    location,
    shouldShowLocation,
  };
};
