import { useRuntimeConfig } from "#app";

export const useGoogleAdsLocationService = () => {
  const config = useRuntimeConfig();

  const getLocationById = async (locationId: string) => {
    try {
      const response = await $fetch(
        `${config.public.justHireUrl}/api/google-ads-locations/${locationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": config.public.justHireKey,
          },
        }
      );
      return response?.data;
    } catch (error) {
      console.error("Error fetching location:", error);
      return null;
    }
  };

  return {
    getLocationById,
  };
};
